.mybutton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background-color: white;
}

.mybutton:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.mybutton img {
    width: 50px;
    height: 50px;
}

.mymybutton h3 {
    font-size: 18px;
    font-weight: 500;
    color: #222;
}

.mybutton:nth-child(1) img {
    filter: invert(37%) sepia(7%) saturate(6366%) hue-rotate(177deg) brightness(92%) contrast(85%);
}

.mybutton:nth-child(2) img {
    filter: invert(48%) sepia(28%) saturate(3743%) hue-rotate(339deg) brightness(96%) contrast(85%);
}

.mybutton:nth-child(3) img {
    filter: invert(37%) sepia(27%) saturate(4366%) hue-rotate(90deg) brightness(52%) contrast(45%);
}

.mybutton:nth-child(4) img {
    filter: invert(48%) sepia(28%) saturate(3743%) hue-rotate(315deg) brightness(96%) contrast(85%);
}

.mybutton:nth-child(5) img {
    filter: invert(48%) sepia(28%) saturate(3743%) hue-rotate(180deg) brightness(96%) contrast(85%);
}

.mybutton:nth-child(6) img {
    filter: invert(37%) sepia(7%) saturate(6366%) hue-rotate(270deg) brightness(92%) contrast(85%);
}

.mybutton:nth-child(7) img {
    filter: invert(37%) sepia(7%) saturate(6366%) hue-rotate(177deg) brightness(92%) contrast(85%);
}

.mybutton:nth-child(8) img {
    filter: invert(48%) sepia(28%) saturate(3743%) hue-rotate(360deg) brightness(96%) contrast(85%);
}
