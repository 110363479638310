.section {
    margin: 40px auto;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 40px;
}

.content {
    padding: 20px;
}