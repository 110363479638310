.loginButtons {
    width: 60%;
    margin: 0 auto;
}

fieldset {
    border: 2px solid black;
    padding: 10px;
}

.mydiv {
    padding: 5px;
}

form {
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

legend {
    font-size: 1.5rem;
    font-weight: bold;
}

label {
    display: block;
    margin-bottom: 0.5rem;
}

input {
    width: 60%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
}

input:focus {
    outline: none;
    border-color: #0066cc;
}

div {
    flex: 1;
}